import React from 'react';
import styled from 'styled-components';

import { device } from '../constants';

interface WrapperProps {
  img?: string;
  color?: string;
}

const Wrapper = styled.section<WrapperProps>`
  background-color: ${props =>
    props.color ?? props.theme.colors.purple};

  position: relative;
  z-index: 1;

  @media screen and ${device.mobileL} {
    background-position: right center;
    background-size: 60%;

    background-image: ${props =>
      props.img
        ? `url('${props.img}')`
        : props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.purple};

    background-repeat: no-repeat;
  }
  @media screen and ${device.laptop} {
    background-position: right center;
    background-size: 50%;
  }
  @media screen and ${device.laptopL} {
    background-position: right 35%;
    background-size: 70%;
  }
`;

const SpeechBubble = styled.div<WrapperProps>`
  background-color: ${props =>
    props.color ?? props.theme.colors.purple};

  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;

  mask: url('/images/design/hero/hero-header.png') no-repeat 0% 0%;
  mask-size: contain;
  -webkit-mask: url('/images/design/hero/hero-header.png') no-repeat 0% 0%;
  -webkit-mask-size: contain;
  
  @media screen and ${device.mobileL} {
    -webkit-mask-size: cover;
  }

  @media screen and ${device.tablet} {
    width: 70%;
    -webkit-mask-position-x: 100%;
  }
`;

const Heading = styled.h1`
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  line-height: 48px;
  padding: ${props => props.theme.paddings.pd}px
    ${props => props.theme.paddings.pd * 1.5}px;

  margin-top: 0;
  margin-bottom: 0;

  &:after {
    height: 0px;
  }

  @media screen and ${device.tablet} {
    font-size: 56px;
    line-height: 72px;
    padding: ${props => props.theme.paddings.pd * 2}px
      ${props => props.theme.paddings.pd * 3}px;
  }
`;

interface Props {
  title: string;
  bgImgSrc?: string;
  color?: string;
  titleImgSrc?: string; // src
}

const Hero: React.FC<Props> = function (props) {
  const { title, bgImgSrc, color, titleImgSrc } = props;

  const wrapperProps: WrapperProps = {
    img: bgImgSrc,
    color,
  };

  return (
    <Wrapper {...wrapperProps}>
      <SpeechBubble color={color} />
      {titleImgSrc ? (
        <img src={titleImgSrc} />
      ) : (
        <Heading color={color}>{title}</Heading>
      )}
    </Wrapper>
  );
};

export default Hero;
