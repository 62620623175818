import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import 'typeface-montserrat';
import 'typeface-open-sans';
import SEO from '../components/SEO';
import config from '../config';

interface Props {
  pageTitle: string;
  bgImgSrc?: string;
  color?: string;
  titleImgSrc?: string;
  path?: string;
}

const WithHeroLayout: React.FC<Props> = function (props) {
  const { children, pageTitle, bgImgSrc, color, titleImgSrc, path } = props;

  return (
    <>
      <Header title={pageTitle} />
      <SEO title={pageTitle} path={path} />
      <Hero
        title={pageTitle}
        bgImgSrc={bgImgSrc}
        color={color}
        titleImgSrc={titleImgSrc}
      />
      <div>
        <main>{children}</main>
        <Footer login={false} title={config.siteMetadata.title}></Footer>
      </div>
    </>
  );
};

export default WithHeroLayout;
