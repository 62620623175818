import React from 'react';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import WithHeroLayout from '../../layouts/WithHeroLayout';
import { device } from '../../constants';
import PressRelease from '../../components/PressRelease';
import orderBy from 'lodash/orderBy';

const Container = styled.div`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`;

const PressReleasesPage: React.FC<PageProps> = function (props) {
  const { location } = props;

  const data = useStaticQuery(graphql`
    query {
      posts: allFile(
        filter: {
          sourceInstanceName: { eq: "post" }
          childMdx: {
            frontmatter: { category: { in: ["Press Releases and Statements"] } }
          }
        }
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                pub_date
                link
                excerpt
                dc_creator
                category
              }
            }
          }
        }
      }

      news: allFile(
        filter: {
          sourceInstanceName: { eq: "news" }
          childMdx: {
            frontmatter: { category: { in: ["Press Releases and Statements"] } }
          }
        }
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                pub_date
                link
                excerpt
                dc_creator
                category
              }
            }
          }
        }
      }
    }
  `);

  const { posts, news } = data;
  const { edges: postsEdges } = posts;
  const { edges: newsEdges } = news;

  const postsEdgesPatched = postsEdges.map(edge => ({ ...edge, type: 'post' }));
  const newsEdgesPatched = newsEdges.map(edge => ({ ...edge, type: 'news' }));

  const allEdges = orderBy(
    [...postsEdgesPatched, ...newsEdgesPatched],
    ['node.childMdx.frontmatter.pub_date'],
    ['desc']
  );

  const theme = useTheme();

  return (
    <WithHeroLayout
      pageTitle="Press Releases"
      bgImgSrc="journal" // @TODO: Make configurable from CMS
      color={theme.colors.purple} // @TODO: Make configurable from CMS
      path={location?.pathname}
    >
      <Container>
        <ContentContainer>
          {allEdges.map(edge => (
            <PressRelease
              pressRelease={edge.node.childMdx.frontmatter}
              type={edge.type}
            />
          ))}
        </ContentContainer>
      </Container>
    </WithHeroLayout>
  );
};

export default PressReleasesPage;
